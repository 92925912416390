// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { navigate, Link, graphql } from 'gatsby';

import queryString from 'query-string';
import * as Yup from 'yup';

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Button, Container } from 'react-bootstrap';
import firebase from 'firebase';
import { Formik } from 'formik';
import { linkResolver } from '../utils/LinkResolver';

import { Spinner } from '../components/Spinner/Spinner';

import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { defaultLanguage } from '../../prismic-configuration';
import PersonalDataForm from '../components/Form-components/PersonalDataForm/BasicPersonalDataForm-component';

function resetPass(auth, oobCode, newPassword) {
  auth
    .verifyPasswordResetCode(oobCode)
    .then(email => {
      const accountEmail = email;
      auth
        .confirmPasswordReset(oobCode, newPassword)
        .then(resp => {
          // Password reset has been confirmed and new password updated.
          // TODO: Display a link back to the app, or sign-in the user directly
          // if the page belongs to the same domain as the app:
          auth.signInWithEmailAndPassword(accountEmail, newPassword);
          navigate('/personal-page');
          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        })
        .catch(error => {
          alert(
            error.message ||
              'Error occurred during confirmation. Please try to reset the password again',
          );
          navigate('/login');
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
        });
    })
    .catch(error => {
      alert(
        error.message || 'Invalid or expired action code. Please try to reset the password again',
      );
      navigate('/login');
    });
  // Save the new password.
}

function handleResetPassword(auth, actionCode) {
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  // Verify the password reset code is valid.
  auth
    .verifyPasswordResetCode(actionCode)
    .then(email => {
      const accountEmail = email;
    })
    .catch(error => {
      alert(
        error.message || 'Invalid or expired action code. Please try to reset the password again',
      );
      navigate('/login');
    });
}

function RecoverPass({ location, data }) {
  const [oobCodeS, setOobCodeS] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isBrowser = typeof window !== 'undefined';
  let firebaseAuth = {};

  if (isBrowser) {
    firebaseAuth = firebase.auth();
  }
  // get params
  useEffect(() => {
    setIsLoading(true);
    const params = queryString.parse(location.search);
    const { oobCode } = params;

    setOobCodeS(oobCode);
    if (firebaseAuth && oobCode) {
      handleResetPassword(firebaseAuth, oobCode);
      setIsLoading(false);
    } else {
      alert('Reset code not found');
      navigate('/login');
    }
  }, [location.search]);

  if (!data) return null;

  const pageContent = data.prismicPage;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const langResolver = lang === defaultLanguage ? '' : `/${lang}`;
  const topMenu = data.prismicMenuLink || {};
  const handleFormikSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);

    setTimeout(() => {
      resetPass(firebaseAuth, oobCodeS, values.password);
      setSubmitting(false);
    }, 400);
  };

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.page_title.text} lang={lang} />
      <Container className="container-success">
        <h1 className="page-title">
          {/* <i className="fa fa-check-circle text-success" /> */}
          {page.page_title.text}
        </h1>

        <div className="">
          <div className="success-message-wrapper">
            <div className="buttons-wrapper">
              {isLoading ? (
                <Spinner />
              ) : (
                <Formik
                  enableReinitialize
                  initialValues={{ password: '', confirmPassword: '' }}
                  isSubmitting
                  validationSchema={Yup.object().shape({
                    password: Yup.string().required('Required'),
                    confirmPassword: Yup.string()
                      .required('Required')
                      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                  })}
                  onSubmit={handleFormikSubmit}
                >
                  {props => (
                    <PersonalDataForm
                      submitButtonLabel="Save"
                      className="professional-portfolio"
                      firebaseAuth={firebaseAuth}
                      mainFormVars={[
                        {
                          description: null,
                          field_type: 'One line Input',
                          form_type: 'password',
                          name: 'password',
                          placeholder: 'Password',
                          radio_options: null,
                          required: false,
                          title: 'New Password',
                        },
                        {
                          description: null,
                          field_type: 'One line Input',
                          form_type: 'password',
                          name: 'confirmPassword',
                          placeholder: 'Confirm Password',
                          radio_options: null,
                          required: false,
                          title: 'Confirm Password',
                        },
                      ]}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                    />
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query P_RecoverPassQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyTable
          ...PageDataBodyText
          ...PageDataBodyQuote
          ...PageDataBodyFullWidthImage
          ...PageDataBodyImageGallery
          ...PageDataBodyImageHighlight
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`;

export default withPrismicPreview(RecoverPass, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
